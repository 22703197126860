let beaver = require("beaver-logger")

const BeaverLogger = () => {
  var map = {
    "notch.superopsbeta.com": "https://lambdaapi.superopsbeta.com/humiolog",
    "notch.superopsalpha.com": "https://lambdaapi.superopsalpha.com/humiolog",
    "superops.ai": "https://lambdaapi.superops.ai/humiolog",
    localhost:
      "https://w4lzrcdjmf.execute-api.us-east-2.amazonaws.com/humiolog",
  }

  const $newlogger = beaver.Logger({
    // Url to send logs to
    url: map[window.location.hostname],

    // Prefix to prepend to all events
    prefix: "Superops website - " + window.location.href,

    // Log level to display in the browser console
    logLevel: beaver.LOG_LEVEL.WARN,

    // Interval to flush logs to server
    flushInterval: 60 * 1000,

    // Use sendBeacon if supported rather than XHR to send logs; defaults to false
    // enableSendBeacon: true,
  })

  $newlogger.addHeaderBuilder(() => {
    return { action: "pushlog" }
  })

  window.logger = $newlogger
}

export default BeaverLogger
